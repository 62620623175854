const verb = [
    'bader',
    'ber',
    'betaler',
    'biter',
    'blåser',
    'danser',
    'drikker',
    'flyr',
    'går',
    'hopper',
    'jogger',
    'kikker',
    'lever',
    'ligger',
    'løper',
    'øver',
    'rasker',
    'reiser',
    'klager',
    'roper',
    'ser',
    'sitter',
    'skriver',
    'sover',
    'spiser',
    'stemmer',
    'svømmer',
    'stiger',
    'stjeler',
    'sykler',
    'synker',
    'våkner',
    'vasker',
    'venter',
    'viker',
    'valser',
    'vugger',
    'spankulerer',
];

export default verb;
