const adverb = [
    'aldri',
    'alltid',
    'best',
    'bra',
    'dårlig',
    'dessverre',
    'faktisk',
    'fornøyd',
    'fort',
    'gjerne',
    'gledelig',
    'godt',
    'helst',
    'høyt',
    'ikke',
    'kanskje',
    'klar',
    'kort',
    'langt',
    'lavt',
    'lenge',
    'lett',
    'lite',
    'motvillig',
    'mye',
    'neppe',
    'nesten',
    'ofte',
    'pent',
    'såklart',
    'sakte',
    'sint',
    'spent',
    'stadig',
    'stille',
    'stygt',
    'suverent',
    'tålmodig',
    'tungt',
];

export default adverb;
