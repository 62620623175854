const adjektiv = [
    'ærlig',
    'alvorlig',
    'ansvarlig',
    'åpen',
    'bedagelig',
    'berømt',
    'bevisst',
    'bråkete',
    'bred',
    'dum',
    'dyp',
    'ekkel',
    'ekte',
    'enkel',
    'ensom',
    'falsk',
    'fantastisk',
    'farlig',
    'fast',
    'fersk',
    'fjern',
    'flau',
    'full',
    'følsom',
    'forsiktig',
    'fremmed',
    'frisk',
    'fryktelig',
    'glatt',
    'gravid',
    'harry',
    'heldig',
    'hemmelig',
    'hjelpsom',
    'hurtig',
    'hyper',
    'hyppig',
    'irritert',
    'kald',
    'kjærlig',
    'kjapp',
    'kjedelig',
    'klønete',
    'kul',
    'laber',
    'langsom',
    'lat',
    'lav',
    'lignende',
    'liten',
    'løs',
    'lovlig',
    'lykkelig',
    'merkelig',
    'modig',
    'modig',
    'mørk',
    'morsom',
    'naturlig',
    'nyttig',
    'rask',
    'ren',
    'rettferdig',
    'rimelig',
    'ryddig',
    'sindig',
    'sint',
    'skarp',
    'skyldig',
    'smal',
    'søt',
    'stille',
    'stolt',
    'stor',
    'stram',
    'streng',
    'stygg',
    'sulten',
    'sunn',
    'super',
    'syk',
    'tam',
    'tykk',
    'tynn',
    'ulovlig',
    'varm',
    'vennlig',
    'vill',
    'villig',
    'væpnet',
];

export default adjektiv;
